import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { filter, map, switchMap } from 'rxjs';

import { UserFacade } from '@passbot/angular/auth';
import { ICredentialGroup, isDefined, PartialWithId, Permissions } from '@passbot/shared';

import { PermissionsFacade } from '../+state';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[needPermission]',
})
export class NeedPermissionDirective implements OnInit, OnChanges {
    @Input() public needPermission: {
        permission: Permissions | 'TenantAdmin' | 'BillingAdmin';
        groupIds?: string[];
        groupId?: string;
        groups?: PartialWithId<ICredentialGroup>[];
    };

    private readonly roleLevelMap = {
        [Permissions.Read]: 0,
        [Permissions.Write]: 1,
        [Permissions.Admin]: 2,
        TenantAdmin: 3,
        BillingAdmin: 4,
    };

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly userFacade: UserFacade,
        private readonly permissionsFacade: PermissionsFacade,
    ) {}

    public ngOnInit(): void {
        this.checkPermission();
    }

    public ngOnChanges() {
        this.checkPermission();
    }

    private checkPermission() {
        const groups = this.needPermission.groupId
            ? [this.needPermission.groupId]
            : this.needPermission.groups
            ? this.needPermission.groups.map((g) => g.id)
            : this.needPermission.groupIds || [];

        // if (groups.length === 0 && this.needPermission.permission !== 'TenantAdmin' && this.needPermission.permission !== 'BillingAdmin') {
        //     this.viewContainer.clear();
        //     return;
        // }

        this.userFacade.getUser$
            .pipe(
                filter(isDefined),
                switchMap((user) =>
                    (groups.length > 0
                        ? this.permissionsFacade.getByUserIdAndGroupIds$(user.id, groups)
                        : this.permissionsFacade.getByUserId$(user.id)
                    ).pipe(map((permissions) => ({ user, permissions }))),
                ),
            )
            .subscribe(({ user, permissions }) => {
                this.viewContainer.clear();

                let hasAccess =
                    (this.needPermission.permission === 'BillingAdmin' && user.billingAdmin) ||
                    (this.needPermission.permission !== 'BillingAdmin' && user.tenantAdmin);

                if (!hasAccess) {
                    permissions.some((perm) => {
                        if (this.roleLevelMap[this.needPermission.permission] <= this.roleLevelMap[perm.permission]) {
                            hasAccess = true;
                            return true;
                        }

                        return false;
                    });
                }

                if (hasAccess) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    return;
                }

                this.viewContainer.clear();
            });
    }
}
