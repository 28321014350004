import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs';

import { ICredentialGroup } from '@passbot/shared';

import { UsersFacade } from '../+state';

@Pipe({ name: 'userAccessCount', pure: false })
export class UserAccessCountPipe implements PipeTransform {
    public usersValue?: number;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly usersFacade: UsersFacade,
    ) {}

    public transform(credentialGroup: ICredentialGroup): number {
        if (!this.usersValue) {
            this.usersValue = 0;

            this.usersFacade
                .getByGroupId$(credentialGroup.id, true)
                .pipe(take(1))
                .subscribe((users) => {
                    this.usersValue = users.length;

                    this.cdr.markForCheck();
                });
        }

        return this.usersValue || 0;
    }
}
